import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../../services/products.service';
import { CollectionRecord, getCollectionByName } from '../../constants/collections.constants';
import { CollectionInfo } from '../../dto/shopify/collections/collection-info.dto';
import { ProductInfo } from '../../dto/shopify/products/product-info.dto';

@Component({
  selector: 'app-product-collection',
  templateUrl: './product-collection.component.html',
  styleUrls: ['./product-collection.component.css']
})
export class ProductCollectionComponent implements OnInit {

  public transparent: boolean = true;

  public collection: CollectionInfo;
  public products: ProductInfo[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly shopifyProductsService: ProductsService,
  ) {

  }

  ngOnInit() {
    this.initializeRouteListener();
  }

  private initializeRouteListener() {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        this.processRouteParams(params);
      },
      error: error => {
        console.error('Error:', error);
      },
    });
  }

  private processRouteParams(params: ParamMap) {

    const collectionName = params.get('collection-name');
    if (!collectionName) return;

    const collection = getCollectionByName(collectionName);
    if (!collection) return;

    this.fillCollection(collection);
    this.fillProducts(collection);
  }

  private fillCollection(collection: CollectionRecord) {

    this.transparent = true;

    this.shopifyProductsService.getCollection(collection.id).subscribe({
      next: (collection: CollectionInfo) => {
        this.collection = collection;

        setTimeout(() => {
          this.transparent = false;
        }, 100);
      },
      error: error => {
        console.error('Error:', error);
      },
    });
  }

  private fillProducts(collection: CollectionRecord) {
    this.shopifyProductsService.getCollectionProducts(collection.id).subscribe({
      next: (products: ProductInfo[]) => {
        this.products = products;
      },
      error: error => {
        console.error('Error:', error);
      },
    });
  }

}
