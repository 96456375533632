<header class="header">
  <nav class="navbar navbar-expand-xl navbar-dark bg-lienzarte px-lg-5 py-3 shadow mb-4">
    <div class="container">
      <a class="navbar-brand" href="../../../../../index.html">
        <img class="navbar-brand-img" src="assets/img/logo/lienzarte-logo-white-letters.png" alt="lienzarte-logo-white">
      </a>
      <div class="d-flex">
        <button class="navbar-toggler navbar-toggler-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarContent"
                aria-controls="navbarContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <svg class="svg-icon navbar-icon">
            <use xlink:href="#menu-hamburger-1"></use>
          </svg>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav navbar-nav-separated mt-3 mt-lg-0">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/admin/orders']">
              <i class="fa-regular fa-shopping-cart text-white"></i>
              Órdenes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/admin/uploads']">
              <i class="fa-regular fa-image-landscape text-white"></i>
              Subida de imágenes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['']">
              Cerrar sesión
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<div class="modal fade modal-right" id="sidebarCart" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content sidebar-cart-content">
      <app-shopping-cart-sidebar></app-shopping-cart-sidebar>
    </div>
  </div>
</div>
